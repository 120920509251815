import { Listing } from "shared-components";
import { translations } from "./Translations";
import { isProject } from "@Utils/Functions";
import { FiltersValues } from "shared-components/Contexts/Filters/model";
import { ListingData } from "shared-components/ViewFragments/SearchPage/ListingCard";
import { SetStateAction } from "react";

export const thereIsTranslations = countryCode => {
	const keyExist = Object.keys(translations).includes(countryCode);
	return keyExist;
};

export const firstLetterToUppercase = word => {
	const editedWord = word?.charAt(0)?.toUpperCase() + word?.slice(1);
	return editedWord;
};

export const getStratumText = stratum => {
	switch (stratum) {
		case 110:
			return "Sin especificar";
		case 100:
			return "Campestre";
		case 0:
			return "Estrato 0";
		case 1:
			return "Estrato 1";
		case 2:
			return "Estrato 2";
		case 3:
			return "Estrato 3";
		case 4:
			return "Estrato 4";
		case 5:
			return "Estrato 5";
		case 6:
			return "Estrato 6";
		default:
			return "Sin especificar";
	}
};

export const getFormattedFilters = filters => {
	if (filters?.locations) {
		const correctLocations = [
			{
				type: filters.locations[0]?.type,
				id: filters.locations[0].id,
				name: filters.locations[0]?.name,
			},
		];

		return {
			...filters,
			locations: correctLocations,
		};
	} else return filters;
};

export const getProjectCommertialUnitsOrdered = ({listing, filters} : {listing : Listing | ListingData, filters?: FiltersValues}) => {
    if (!isProject (listing)) return null
    let commertialUnits = listing.commercial_units.sort((a, b) => a.price.amount - b.price.amount);

    if (filters) {
        const { minPrice, maxPrice, bedrooms, bathrooms, m2Min, m2Max, order } = filters;

		commertialUnits = [...commertialUnits].filter(unit => {
			let isValid = true;

			if (minPrice && unit.price.amount < minPrice) {
				isValid = false;
			}

			if (maxPrice && unit.price.amount > maxPrice) {
				isValid = false;
			}

			if (bedrooms && !bedrooms.includes(unit.bedrooms)) {
				isValid = false;
			}

			if (bathrooms && !bathrooms.includes(unit.bathrooms)) {
				isValid = false;
			}

			if (m2Min && unit.m2 <= m2Min) {
				isValid = false;
			}

			if (m2Max && unit.m2 >= m2Max) {
				isValid = false;
			}

            return isValid;
        })

        if (order) {
            switch (order) {
                case 0:
                    commertialUnits.sort((a, b) => b.price.amount - a.price.amount);
                    break;
                case 4:
                    commertialUnits.sort((a, b) => a.m2 - b.m2);
                    break;
                case 5:
                    commertialUnits.sort((a, b) => b.m2 - a.m2);
                    break;
                default:
                    break;
            }
        }
    }
    
    return commertialUnits
}

export const getProjectRangePrices = ({
	listing,
	filters,
}: {
	listing: Listing | ListingData;
	filters?: FiltersValues;
}) => {
	const commercialUnits = getProjectCommertialUnitsOrdered({ listing, filters });

	if (commercialUnits && !!commercialUnits.length) {
		const minPrice = { ...commercialUnits[0].price, currency: listing.price.currency };
		const maxPrice = {
			...commercialUnits[commercialUnits.length - 1].price,
			currency: listing.price.currency,
		};

		return { minPrice, maxPrice };
	} else {
		return { minPrice: null, maxPrice: null };
	}
};

function cleanSpecialCharacters(string) {
	string = string.replace(/[ñÑ]/g, "n");
	string = string.replace(/[áÁàÀâÂäÄãÃ]/g, "a");
	string = string.replace(/[éÉèÈêÊëË]/g, "e");
	string = string.replace(/[íÍìÌîÎïÏ]/g, "i");
	string = string.replace(/[óÓòÒôÔöÖõÕ]/g, "o");
	string = string.replace(/[úÚùÙûÛüÜ]/g, "u");
	string = string.replace(/[ýÝÿ]/g, "y");
	string = string.replace(/[çÇ]/g, "c");
	string = string.replace(/ - /g, "-");
	string = string.replace(/-\//g, "/");
	string = string.replace(/ \/ /g, "-y-");
	string = string.replace(/[.,]/g, "");
	string = string.replace(/[()]/g, "");
	string = string.replace(/!/g, "");
	string = string.replace(/'/g, "");
	string = string.replace(/\//g, "");
	string = string.replace(/--/g, "-");
	string = string.replace(/_/g, "-");
	string = string.replace(/\s+/g, "-");
	string = string.replace(/\s+/g, "");
	return string.toLowerCase();
}

export const getInmoLink = (id, name) => {
	let formattedName = cleanSpecialCharacters(name);
	let url = `/inmobiliarias/perfil/${id}-${formattedName}`;

    return url;
}

export const getOptionsSelectedText = (filterTypeObj, filterType, filterIsProject = false) => {
	if (Array.isArray(filterType)) {
   		return filterTypeObj.filter((filter) => filterType.includes(filter.value)).map(v => v.text).join(', ')
	} else if (typeof filterType === "number") {
		const currentFilter = filterTypeObj.filter((filter) => filter.value === filterType);
		const projectFilter = filterIsProject ? currentFilter.find((filter) => filter.projects) : null;

		return (projectFilter || currentFilter[0]).text
	} else return ""
}

export const formatNumber = num => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const validateRange = (min:(string | number), max:(string | number), message:string) => {
    if (Number(min) && Number(max) && Number(min) > 0 && Number(max) > 0) {
        return Number(min) > Number(max)
            ? message
            : '';
    }
    return '';
};

export const getOperationId = (id, isProjectMode) => {
	const optionType = isProjectMode ? "projects" : "normal";

	return `${id}-${optionType}`
}

export const extractOperationValues = (string) => {
	const [num, type] = string.split("-");
	const numValue = parseInt(num, 10);
	const typeValue = type === "projects"; 

	return {
		id: numValue,
		projects: typeValue
	}
}